/*Base styles*/
*{
  margin: 0;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  color: "#333";
  background-color: transparent;
  box-sizing: border-box;
}

#root{
  --main-bg-color: #e1e1e1;
  --highlight-color: rgb(100, 100, 100);
  --highlight-color-weak: rgba(118, 0, 196, 0.25);
  --block-color: rgb(74, 74, 74);
  --text-color: rgb(71, 71, 71);
  --text-color-overlay: rgb(255, 255, 255);
  --content-margin: 5%;
  --frosted-color: rgba(255, 255, 255, 0.5);
  --frosted-hover: rgba(185, 185, 185, 0.5);
}

.App{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

p{
  text-align: justify;
  line-height: 1.6;
  font-size: large;
  background-color: transparent;
}

.navbar{
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 100;
  width: 100%;
  box-sizing: border-box;
  padding: 1% var(--content-margin) 1% var(--content-margin);
  max-width: 100%;
  border-bottom: 4px solid var(--highlight-color);
  background-color: var(--main-bg-color);
  transition: all 0.3s;
}

.navbar.scrolled{
  padding: 0px var(--content-margin) 0px var(--content-margin);
  transition: all 0.3s;
}

.navbar h1 {
  font-size: 2em;
  color: var(--text-color);
}

.navbar .links {
  margin-left: auto;
  font-size: 1.5em;
}

.navbar a {
  margin-right: 16px;
  text-decoration: none;
  padding: 4px;
  color: var(--text-color);
  font-weight: bold;
  border-left: 4px solid var(--highlight-color);
}

.navbar a:hover {
  color: var(--highlight-color);
}

.dropdown{
  position: relative;
  display: inline-block;
  padding: 4px;
  background-color: var(--main-bg-color);
}

.dropdown-content{
  display: none;
  position: absolute;
  font-weight: bold;
  box-sizing: border-box;
  width: fit-content;
  top: 100%;
  z-index: 1;
  border-left: 4px solid var(--highlight-color);
  background-color: var(--main-bg-color);
}

.dropdown:hover .dropdown-content{
  display: flex;
  flex-direction: column;
}

.navbar .dropdown-item{
  box-sizing: border-box;
  width: auto;
  text-align: left;
  color: var(--text-color);
  background-color: #e1e1e100;
  border-left: 0px solid var(--highlight-color);
  margin: 0;
}

.navbar .dropdown-item:hover{
  background-color: var(--frosted-hover);
}

.backgroundContainer{
  position: fixed;
  background-image: url("/src/Images/Panorama.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.content {
  flex-grow: 1;
  display: flex;
  position: relative;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  box-sizing: border-box;
}

.pageHeader{
  color: var(--text-color);
  margin-left: 10%;
  width: fit-content;
  text-align: left;
}

.frosted{
  border-radius: 8px;
  backdrop-filter: blur(4px);
  background-color: var(--frosted-color);
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.homePage{
  flex: 1;
  display: flex;
  align-items: center;
}

.homePageImage{
  height: 60vh;
  border-radius: 10px;
}

.dropshadow{
  box-shadow: 5px 5px 5px var(--block-color);
}

.homeNavButtons{
  text-align: left;
  width: fit-content;
  height: fit-content;
  margin-left: 16vw;
  display: flex;
  flex-direction: column;
  border-left: 4px solid var(--highlight-color);
  background-color: rgba(255, 255, 255, 0.4);
}

.homeNavButtons a{
  background-color: transparent;
}

.navButton{
  color: var(--main-bg-color);
  min-height: 50px;
  height: fit-content;
  width: 150px;
  font-size: 1.5em;
  border-radius: 5px;
  margin: 1vh;
  cursor: pointer;
  border: none;
  background-color: var(--block-color);
}

.navButton:hover{
  background-color: var(--highlight-color);
  /*box-shadow: 0 0 10px 3px var(--highlight-color);*/
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.About{
  margin-top: 2vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.About-contents{
  display: flex;
  flex-direction: column;

  height: fit-content;
  padding: 20px;
}

.AboutBlock{
  padding: 10px;
  margin-bottom: 10px;
}

.AboutBlock .title{
  font-weight: bold;
}

.About-image{
  height: 50vh;
  margin: 20px;
  box-shadow: 5px 5px 4px 0px var(--highlight-color);
}

.portfolioBlock{
  display: flex;
  align-items: center;
  margin-left: var(--content-margin);
  margin-right: var(--content-margin);
  background-color: transparent;
  max-width: 100%;
  box-sizing: border-box;
}

.portfolioBlock p{
  padding: 5px;
  margin: 10px;
  font-size: 1.2em;
}

.portfolioBlock img{
  border-radius: 8px;
  height: 40vh;
  margin: 10px;
}

.portfolioList{
  display: flex;
  flex-direction: row;
  max-width: 100%;
  margin-top: 8px;
  margin-left: var(--content-margin);
  margin-right: var(--content-margin);
}

.portfolioPage{
  max-width: 100%;
  box-sizing: border-box;
}

.portfolioItem{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 300px;
  margin: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-decoration: none;
  position: relative;
  transition: all 0.5s;
}

.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.5s;
}

.portfolioItem:hover .overlay{
  opacity: 0;
  transition: all 0.5s;
}

.portfolioItem:hover p{
  opacity: 0;
  transition: all 0.5s;
}

.portfolioItem:hover{
  content: "";
  transform: scale(1.05);
  background: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /*filter: blur(4px);*/
  transition: all 0.5s;
}

.portfolioItem p{
  color: var(--text-color-overlay);
  text-align: center;
  font-size: 2.2em;
  font-weight: bold;
  z-index: 1;
  transition: all 0.5s;
}

.ContactLink{
  color: black;
  text-decoration: none;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding-bottom: 0;
}

.EducationAndWork{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  gap: 10px;
}

.WorkBlock{
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
}

.WorkBlock h3{
  margin-top: 10px;
}

.WorkBlock p{
  text-align: center;
}